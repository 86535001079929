function Button({ onClick, text, loading }) {

    return (
        <button
            className={`bg-red-600 text-white font-inter py-2 px-4 rounded-sm mt-5 buttonload`}
            onClick={onClick}
        >
            <i className={`${loading}`} /> <span>{text}</span>


        </button>
    );
}

export default Button;