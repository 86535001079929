import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Content from './components/Page/Content';

function App() {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  )
}

export default App;
