function Footer() {
    return (
        <footer className="h-[500px] bg-black bottom-0 mt-20">
            <div className="w-3/4 mx-auto">
                <div className="text-white text-inter border-b-[1px] border-slate-600 font-bold	h-16 pt-6">
                    <h1 className="">Precisa de mais ajuda?</h1>
                </div>
                <div className="h-[320px] flex justify-between max-sm:flex-col max-sm:mx-auto">

                    <div className="footer max-sm:pt-14">

                        <h1 className="text-red-700 font-bold ">ATENDIMENTO</h1>
                        <p>Estado de SP: (19)3785-9200</p>
                        <p>Campinas/SP: (19)3303-7000</p>
                        <p>Outros Estados: 0300-785-9200</p>
                    </div>

                    <div className="footer max-sm:pt-2">

                        <p>E-mail: contato@esplane.com.br</p>
                        <p>WhatsApp: (19) 99720-9278</p>
                        <p>Seg. a Sext. - 08h às 18h</p>
                        <p>Sábados. - 08h às 12h</p>
                    </div>
                </div>
                <div className="text-white opacity-25 italic w-full flex items-center justify-center max-sm:pt-11">
                    <h1>Copyright © 2022: esplane.com.br. Todos os direitos reservados</h1>
                </div>
            </div>
        </footer>


    );
}

export default Footer;