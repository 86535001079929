import logo from '../../img/logo-esplane.png'

function Header() {
    return (
        <>
            <header className='bg-black flex'>
                <div className='w-3/4 mx-auto flex flex-row items-center'>
                    <div className='h-16 pr-2'>
                        <img src={logo} alt='Esplane' className='h-14 pt-3' />
                    </div>
                    <div className='text-white text-inter flex-start pl-3 border-l-2'>
                        <h1 className=''>Central de Ajuda</h1>
                    </div>
                </div>



            </header>
        </>
    );
}

export default Header;