import { useState } from "react";
import Button from "../Button/Button";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";


function Textarea({ onSubmit }) {
    const [textareaValue, setTextareaValue] = useState('');
    const [isTextareaEmpty, setIsTextareaEmpty] = useState(true);
    const [borderColor, setBorderColor] = useState('border-gray-300');
    const [repactha, setRepactha] = useState(null);
    const [result, setResult] = useState('');
    const [text, setText] = useState('ENVIAR DENÚNCIA');
    const [loading, setLoading] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false)


    const handleTextareaChange = (event) => {
        const newValue = event.target.value;

        if (newValue.length <= 1000) {
            setTextareaValue(newValue);
            if (newValue.trim() === '') {
                setIsTextareaEmpty(true);
                setBorderColor('border-red-600');
            } else {
                setIsTextareaEmpty(false);
                setResult(newValue)
                setBorderColor('border-gray-300');

            }
        }
    }

    function onChange(value) {
        // console.log("Captcha value:", value);
        setRepactha(value)
    }

    const apiUrl = process.env.REACT_APP_VRCL === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_QA;

    const resposta = {
        mensagem: result,
    };


    const handleEnviar = () => {
        if (isSubmitted) {
            return; // Impedir múltiplos cliques enquanto o envio estiver em andamento
        }
        if (textareaValue === '') {
            setBorderColor('border-red-600');
        } else {
            setBorderColor('border-gray-300');
            // console.log(result);
        }
        if (textareaValue !== '' && repactha !== null) {
            setLoading('fa fa-circle-o-notch fa-spin')
            setIsSubmitted(true)
            setText('ENVIANDO')
            axios.post(`${apiUrl}/denuncia/`, resposta, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "ooooooo",
                },
            })
                .then((response) => {
                    // console.log('Entrou na api e enviou a resposta')
                    // console.log(response.data);
                    setLoading(false); // Lidar com erros e desativar o indicador de carregamento
                    onSubmit();
                })
                .catch((error) => {
                    console.log(error.message);
                    setLoading(false); // Lidar com erros e desativar o indicador de carregamento
                });
            // console.log(repactha)
            // console.log(result, 'result');
        }
    };

    return (
        <div className={`mt-2 md:ml-0 ${isTextareaEmpty ? "border-red-500" : "border-gray-300"}`}>
            <textarea
                className={`p-2 border w-full h-32 rounded-md resize-none outline-none ${borderColor}`}
                placeholder="Nos diga qual sua denúncia."
                value={textareaValue}
                onChange={handleTextareaChange}
            />
            {borderColor === 'border-red-600' && (
                <p className="text-red-600">Este campo não pode estar vazio.</p>
            )}
            <div className="text-right text-gray-500">
                {textareaValue.length}/1000
            </div>
            <ReCAPTCHA
                sitekey="6LdMn_4oAAAAAL1Fd27eAjgTrngKiRUkh8jgxZ0j"
                onChange={onChange}
            />,
            <Button
                text={text}
                loading={loading}
                onClick={handleEnviar}
            />
        </div>
    );
}

export default Textarea;